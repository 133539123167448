import "./Footer.css";
import policies from "./PoliciesContent";
import {
  FaInstagramSquare,
  FaFacebookSquare,
  FaWhatsappSquare,
} from "react-icons/fa";

function Footer({ onHandlePrompt, onHandleLanguage, curLanguage }) {
  const curYear = new Date().getFullYear();

  // const testObj = {
  //   payments: {
  //     title: "Payment Policies",
  //     content: [
  //       "The tolerance period for delays is 10 minutes, with minutes being the maximum limit. If the deadline is exceeded, 80% of the total service value will be charged.",
  //       "Have you scheduled and confirmed the appointment in the app? You have up to 24 hours (1 day) before the appointment to cancel. This allows us enough time to accommodate another client in that slot.",
  //       "Cancellations within less than 24 hours or no-shows will be charged a percentage of the service fee.",
  //       "Payment methods: Cash or Card.",
  //     ],
  //     notes: [
  //       `These measures are aimed at providing the best service for our clients. We always prioritize the commitment to punctuality and being fully dedicated to each appointment. It's not fair to cause delays for the entire schedule due to late clients. That's why we've established a time limit.`,
  //       `Plausible justifications for delays or no-shows will be evaluated and taken into consideration. We rely on your good judgment and partnership.`,
  //     ],
  //   },
  // };
  return (
    <div className="footer">
      <div className="grid--footer">
        <div className="logo-col">
          <div>
            <a href="#hero" className="footer-logo">
              {/* <img src="./logo-small.png" alt="Company Logo" class="logo-img" /> */}
              <img
                className="footer-logo-img"
                src="footer-logo-2.webp
                "
                alt="Company Logo"
              />
              {/* <div className="footer-logo--text">
                <h1 className="logo-h">Chapter Two</h1>
                <div className="logo-tex">Second-Hand Book Store</div>
              </div> */}
            </a>
            <ul className="social-links">
              <li>
                <a
                  className="footer-link"
                  href="https://instagram.com/gentlemensclub.barbershop?igshid=MzRlODBiNWFlZA=="
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagramSquare className="social-icon" />
                </a>
              </li>
              <li>
                <a
                  className="footer-link"
                  href="https://www.facebook.com/Gentlemensclubma?mibextid=LQQJ4d"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookSquare className="social-icon" />
                </a>
              </li>
              <li>
                <a
                  className="footer-link"
                  href="https://wa.me/message/S6C4E5WQQNUBD1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaWhatsappSquare className="social-icon" />
                </a>
              </li>
              {/* <li>
                <span
                  className="footer-link"
                  onClick={() => onHandleLanguage()}
                >
                  <FaWhatsappSquare className="social-icon" />
                </span>
              </li> */}
            </ul>
          </div>
          <p className="copyright">
            Copyright &copy;{curYear} by Gentlemen's Club, Inc. All rights
            reserved.
          </p>
        </div>
        <div className="address-col">
          <p className="footer-heading">
            {curLanguage === "en" ? `Contact us` : `Contato`}
          </p>
          <ul className="footer-nav">
            <li>
              <a
                className="footer-link"
                href="https://www.google.com/maps/place/Gentlemen's+Club+Barbershop/@42.4920394,-71.1008219,17z/data=!3m1!4b1!4m6!3m5!1s0x89e375794459cb47:0xc22604cec72aa25!8m2!3d42.4920394!4d-71.1008219!16s%2Fg%2F11kqfqzfq8?entry=ttu"
                target="_blank"
                rel="noreferrer"
              >
                157 Main St, Stoneham, MA
              </a>
            </li>
            <li>
              <a className="footer-link" href="tel:857-253-1766">
                (857) 253-1766
              </a>
            </li>
            <li>
              <a className="footer-link" href="mailto:contact@adventurejp.com">
                bsgentlemensclub@hotmail.com
              </a>
            </li>
          </ul>
        </div>
        <nav className="nav-col">
          <p className="footer-heading">Booksy</p>
          <ul className="footer-nav">
            <li>
              <a
                className="footer-link"
                href="https://booksy.com/en-us/469768_gentlemens-club-barbershop_barber-shop_22298_stoneham?hl=en-US&gei=VQ4kZeDnNYzzptQPn46m2AU&rwg_token=AOZt1jUb7suHnyWg-AufvN5oj06M23-7Jr9lT-G_fegOl9CIeIj4dmilTDn7x2rDznZ0q-4cV70_4NwZGzKSWId4t8Xw7D4E_A%3D%3D"
                target="_blank"
                rel="noreferrer"
              >
                Website
              </a>
            </li>
            <li>
              <a
                className="footer-link"
                href="https://apps.apple.com/us/app/booksy-for-customers/id723961236"
                target="_blank"
                rel="noreferrer"
              >
                iOS app
              </a>
            </li>
            <li>
              <a
                className="footer-link"
                href="https://play.google.com/store/apps/details?id=net.booksy.customer&pli=1"
                target="_blank"
                rel="noreferrer"
              >
                Android app
              </a>
            </li>
          </ul>
        </nav>
        <nav className="nav-col">
          <p className="footer-heading">
            {curLanguage === "en" ? "Company" : "A Empresa"}
          </p>
          <ul className="footer-nav">
            {/* <li>
              <a
                className="footer-link"
                href="home"
                target="_blank"
                rel="noreferrer"
              >
                {curLanguage === "en" ? "Company Info" : "Quem Somos"}
              </a>
            </li>
            <li>
              <a
                className="footer-link"
                href="home"
                target="_blank"
                rel="noreferrer"
              >
                {curLanguage === "en" ? "Carrers " : "Carreiras"}
              </a>
            </li> */}

            <li>
              <span
                className="footer-prompt-btn"
                // First argument => policies + element (policies.payment)
                // Second argument => indicates what frame it will use ("policy", "service"))
                onClick={() => onHandlePrompt(policies.info, "policy")}
              >
                {curLanguage === "en" ? "Info" : "Quem Somos"}
              </span>
            </li>
            <li>
              <span
                className="footer-prompt-btn"
                // First argument => policies + element (policies.payment)
                // Second argument => indicates what frame it will use ("policy", "service"))
                onClick={() => onHandlePrompt(policies.careers, "policy")}
              >
                {curLanguage === "en" ? "Careers " : "Carreiras"}
              </span>
            </li>
            <li>
              <span
                className="footer-prompt-btn"
                // First argument => policies + element (policies.payment)
                // Second argument => indicates what frame it will use ("policy", "service"))
                onClick={() => onHandlePrompt(policies.partnership, "policy")}
              >
                {curLanguage === "en" ? "Partnership" : "Parcerias"}
              </span>
            </li>
          </ul>
        </nav>
        <nav className="nav-col">
          <p className="footer-heading">
            {curLanguage === "en" ? "Policies" : "Transparência"}
          </p>
          <ul className="footer-nav">
            <li>
              <span
                className="footer-prompt-btn"
                // First argument => policies + element (policies.payment)
                // Second argument => indicates what frame it will use ("policy", "service"))
                onClick={() => onHandlePrompt(policies.company, "policy")}
              >
                {curLanguage === "en" ? "Company policy" : "Termos"}
              </span>
            </li>
            <li>
              <span
                className="footer-prompt-btn"
                // First argument => policies + element (policies.payment)
                // Second argument => indicates what frame it will use ("policy", "service"))
                onClick={() => onHandlePrompt(policies.membership, "policy")}
              >
                {curLanguage === "en" ? "Membership policy" : "Membership"}
              </span>
            </li>
            {/* <li>
              <span
                className="footer-prompt-btn"
                // First argument => policies + element (policies.payment)
                // Second argument => indicates what frame it will use ("policy", "service"))
                onClick={() => onHandlePrompt(policies.payment, "policy")}
              >
                {curLanguage === "en"
                  ? "Payment policy"
                  : "Termos de Pagamento"}
              </span>
            </li> */}
            <li>
              <div className="footer-prompt-btn">{""}</div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Footer;
