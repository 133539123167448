import "./Services.css";
import servicesData from "./ServicesContent";
import { RxComponent1 } from "react-icons/rx";

export default function Services({ curLanguage, onHandlePrompt }) {
  return (
    <section className="section-services" id="services">
      <div className="component services-content">
        <h2 className="components-header-h2">
          {curLanguage === "en" ? "Services" : "Serviços"}
        </h2>
        <div className="services-grid">
          <ServicesCard
            img="barbershop.webp"
            title={curLanguage === "en" ? "Barbershop" : "A Barbearia"}
            list={[
              "haircut",
              "hotTowelShave",
              "headShave",
              "haircutWithMachine",
              "lineUp",
              "kidsHaircut",
            ]}
            note={
              curLanguage === "en"
                ? "* Available membership option"
                : "* Disponível no membership"
            }
            onHandlePrompt={onHandlePrompt}
            servicesData={servicesData}
            curLanguage={curLanguage}
          />
          <ServicesCard
            img="face-cream.webp"
            title={curLanguage === "en" ? "Other Services" : "Outros Serviços"}
            list={[
              "eyebrowsShaping",
              // "eyebrowsCleaning",
              "earWaxing",
              "noseWaxing",
              "blackFaceMask",
              "scrubHair",
            ]}
            onHandlePrompt={onHandlePrompt}
            servicesData={servicesData}
            curLanguage={curLanguage}
          />

          <ServicesCard
            img="chemical-2.webp"
            title={curLanguage === "en" ? "Chemicals" : "Tratamentos"}
            list={[
              "brazilianBlowout",
              "highlights",
              "allBlond",
              "hairColoring",
              "hairHydration",
              // "beardColoring",
              "beardHydration",
            ]}
            onHandlePrompt={onHandlePrompt}
            servicesData={servicesData}
            curLanguage={curLanguage}
          />
        </div>
        <p></p>
      </div>
    </section>
  );
}

function ServicesCard({
  curLanguage,
  img,
  title,
  list,
  note,
  onHandlePrompt,
  servicesData,
}) {
  return (
    <div className="services-grid-box">
      <div className="services-grid-box-img">
        <img src={img} alt="Barbershop Drawing"></img>
        <h3>{title}</h3>
      </div>
      <div className="services-grid-box-content">
        <ul>
          {list.map((item) => (
            <li
              key={servicesData[`${item}`].en.title}
              onClick={() => onHandlePrompt(servicesData[`${item}`], "service")}
              className="service-li"
            >
              <div>
                <RxComponent1 className="services-icon" />
              </div>
              <div className="services-item">
                {/* the first [] it the item in the array with the same name, the second [] if the language, no need to put a . in between. eg. of what the code bellow represents 'servicesData.haircut.en.title */}
                {servicesData[`${item}`][`${curLanguage}`].title}
              </div>
            </li>
          ))}
          {note !== "" ? <p className="services-note">{note}</p> : ""}
        </ul>
      </div>
    </div>
  );
}
