import "./About.css";

export default function About({ curLanguage }) {
  return (
    <div className="component about" id="about">
      <div className="about-img-div"></div>
      <div className="about-content">
        <h2 className="components-header-h2">
          {curLanguage === "en" ? "About" : "A Barbearia"}
        </h2>
        <AboutContent curLanguage={curLanguage} />
      </div>
    </div>
  );
}

function AboutContent({ curLanguage }) {
  if (curLanguage === "en") {
    return (
      <>
        <p>
          With the intention to bring a new concept of barbershop,{" "}
          <span className="about-bold">Mr. Samuel Moreira</span> would like to
          introduce you to the{" "}
          <span className="about-bold">Gentlemen’s Club.</span>
        </p>
        <p>
          A place with an extensive list of services focused on mens' hair,
          beard, esthetics and more!
        </p>
        <p>
          And we are giving our customers the advantage of our exclusive{" "}
          <span className="about-bold">Membership</span> with unlimited access
          to haircut and barber services.
        </p>
      </>
    );
  }
  if (curLanguage === "pt") {
    return (
      <>
        <p>
          Com a intenção de trazer um novo conceito de barbearia,{" "}
          <span className="about-bold">Sr. Samuel Moreira</span> gostaria de lhe
          apresentar o <span className="about-bold">Gentlemen’s Club.</span>
        </p>
        <p>
          Um lugar com uma extensa lista de serviços focados em cabelo, barba,
          estética masculina e muito mais!
        </p>
        <p>
          E estamos oferecendo aos nossos clientes a vantagem de nosso{" "}
          <span className="about-bold">Membro</span> com acesso ilimitado a
          cortes de cabelo e serviços de barbeiro.
        </p>
      </>
    );
  }
}
