import "./GroomsDay.css";

export default function GroomsDay({ curLanguage }) {
  return (
    <section className=" groomsDay" id="groomsDay">
      <div className="component">
        <div className="groomsDay-grid">
          <div className="groomsDay-content">
            <h2 className="components-header-h2">
              {curLanguage === "en" ? "Groom's Day" : "Dia do Noivo"}
            </h2>
            <GroomsDayContent curLanguage={curLanguage} />
          </div>
          <div className="groomsDay-img-box"></div>
        </div>
      </div>
    </section>
  );
}

function GroomsDayContent({ curLanguage }) {
  if (curLanguage === "en") {
    return (
      <>
        <p>
          {curLanguage === "en" ? "Groom's Day" : "Dia do Noivo"}
          At the <span className="groomsDay-bold">Gentlemen's Club</span>, we
          provide our customers with the Groom's Day service. You can book the
          barbershop space exclusively for you and your guests, in a tailored
          way that fits your preferences. You can also include food and
          beverages.
        </p>
        <p>
          <span className="groomsDay-bold">Contact us</span> with the number of
          guests and date, and we can provide you with a{" "}
          <span className="groomsDay-bold">free estimate</span>.
        </p>
      </>
    );
  }
  if (curLanguage === "pt") {
    return (
      <>
        <p>
          Aqui no <span className="groomsDay-bold">Gentlemen's Club</span>,
          oferecemos aos nossos clientes o serviço de Dia do Noivo. Você pode
          reservar o espaço da barbearia exclusivamente para você e seus
          convidados, de acordo com as suas preferências. Você também pode
          incluir comidas e bebidas.
        </p>
        <p>
          <span className="groomsDay-bold">Entre em contato conosco</span> com o
          número de convidados e data, então poderemos fornecer um{" "}
          <span className="groomsDay-bold">orçamento gratuito</span>.
        </p>
      </>
    );
  }
}
