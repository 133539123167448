import React, { useState, useEffect } from "react";
import { HiArrowLeftCircle, HiArrowRightCircle } from "react-icons/hi2";
import "./Team.css";

const teamMembers = [
  {
    fullName: "Samuel Pedro",
    enTitle: "Owner",
    ptTitle: "Proprietário",
    experience: "9",
    imageURL: "../../../samuel-3.webp",
  },
  {
    fullName: "Lais Abreu",
    enTitle: "Manager",
    ptTitle: "Administradora",
    experience: "5",
    imageURL: "../../../lais-2.webp",
  },
  {
    fullName: "Kauã Silva",
    enTitle: "Barber / Hairstylist",
    ptTitle: "Barbeiro / Cabeleireiro",
    experience: "7",
    imageURL: "../../../kaua-2.webp",
  },
  {
    fullName: "Cleyton Almeida",
    enTitle: "Barber / Hairstylist",
    ptTitle: "Barbeiro / Cabeleireiro",
    experience: "4",
    imageURL: "../../../cleyton-2.webp",
  },
  {
    fullName: "Estenio Souza",
    enTitle: "Barber / Hairstylist",
    ptTitle: "Barbeiro / Cabeleireiro",
    experience: "7",
    imageURL: "../../../estenio-2.webp",
  },
];

export default function Team({ curLanguage }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [clicked, setClicked] = useState(false);
  let itemsPerPage = 4;

  // Update the window width when the window is resized
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add a listener to the window's resize event
    window.addEventListener("resize", handleResize);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // The empty dependency array ensures this effect runs only once on mount

  // Change the number of cards synchronized with the media query
  if (windowWidth < 944) {
    itemsPerPage = 3;
  }
  if (windowWidth < 704) {
    itemsPerPage = 2;
  }
  if (windowWidth < 544) {
    itemsPerPage = 1;
  }

  const handleNextPage = () => {
    setClicked(true);
    setCurrentPage((prevPage) => (prevPage + 1) % teamMembers.length);
  };
  const handleNextInterval = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % teamMembers.length);
  };

  const handlePreviousPage = () => {
    setClicked(true);
    setCurrentPage((prevPage) =>
      prevPage === 0 ? teamMembers.length - 1 : prevPage - 1
    );
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     handleNextPage();
  //   }, 10000); // Change image every 10 seconds

  //   return () => clearInterval(interval);
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    let interval = null;

    if (!clicked) {
      interval = setInterval(() => {
        handleNextInterval();
      }, 5000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [clicked]);

  return (
    <div className="team" id="team">
      <div className="component team-box">
        <h2 className="components-header-h2">
          {curLanguage === "en" ? "Our Team" : "Colaboradores"}
        </h2>
        <div className="team-box-grid">
          {getVisibleMembers(currentPage).map((member, index) => (
            <TeamCard
              setClicked={setClicked}
              curLanguage={curLanguage}
              key={index}
              fullName={member.fullName}
              title={curLanguage === "en" ? member.enTitle : member.ptTitle}
              experience={member.experience}
              imageURL={member.imageURL}
            ></TeamCard>
          ))}

          <div className="side-btn-left">
            <button
              className="team-button previous-button"
              onClick={handlePreviousPage}
            >
              <HiArrowLeftCircle className="team-button-icon" />
            </button>
          </div>
          <div className="side-btn-right">
            <button
              className="team-button next-button"
              onClick={handleNextPage}
            >
              <HiArrowRightCircle className="team-button-icon" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  function getVisibleMembers(currentPage) {
    const start = currentPage;
    const end = (start + itemsPerPage) % teamMembers.length;
    const members = [];

    for (let i = start; i !== end; i = (i + 1) % teamMembers.length) {
      members.push(teamMembers[i]);
    }

    return members;
  }
}

function TeamCard({
  setClicked,
  curLanguage,
  fullName,
  title,
  experience,
  imageURL,
}) {
  const styleB = {
    backgroundImage: `linear-gradient(
      to top,
      rgba(0, 0, 0, 1),
      rgba(68, 68, 68, 0.25)
    ), url(${imageURL})`,
    backgroundSize: "cover",
    backgroundPosition: "top",
    transition: "all 0.5s ease",
  };

  return (
    <div
      className="team-card"
      style={styleB}
      onMouseEnter={() => setClicked(true)}
      onMouseLeave={() => setClicked(false)}
    >
      <div>
        <span className="team-card-title">{fullName}</span>
        <div className="team-card-content">
          <div className="team-card-text">{title}</div>
          <div className="team-card-text">
            {experience}
            {curLanguage === "en"
              ? "+ years of experience"
              : "+ anos de experiência"}
          </div>
        </div>
      </div>
    </div>
  );
}
