const policies = {
  info: {
    en: {
      title: "Info",
      membershipTerms: {
        title: "Coming soon!",
        content: [
          "The Gentlemen's Club Barbershop was founded in 2023, it's located in Stoneham, MA. ",
          "We are a barbershop focused on all types of services where the modern and old school meet and every gentlemen can find what they need.",
        ],
      },
      membershipNotes: {
        title: "",
        content: [
          "For Company Policy visit Policies > Company Policy.",
          "For details about the Membership visit Policies > Membership.",
        ],
      },
    },
    pt: {
      title: "Informações Importantes",
      membershipTerms: {
        title: "Quem somos",
        content: [
          "O Gentlemen's Club Barbershop foi criado 2023 e está localizado em Stoneham, MA. ",
          "Nós somos uma barbearia com foco em todos os tipos de serviços onde o moderno e clássico se encontram, e cavalheiros de todas as idades encontram o que eles precisam.",
        ],
      },
      membershipNotes: {
        title: "Observações:",
        content: [
          "Para Termos de Pagamento, Reembolso ou Cancelamento visite Transparência > Termos.",
          "Para detalhes sobre o Membership visite Transparência > Membership.",
        ],
      },
    },
  },
  careers: {
    en: {
      title: "Careers",
      membershipTerms: {
        title: "",
        content: [],
      },
      membershipNotes: {
        title: "Join Us",
        content: ["At the moment we don't have any open positions."],
      },
    },
    pt: {
      title: "Carreias",
      membershipTerms: {
        title: "",
        content: [],
      },
      membershipNotes: {
        title: "Junte-se a nossa equipe",
        content: ["No momento não estamos contratando."],
      },
    },
  },
  partnership: {
    en: {
      title: "Partnership",
      membershipTerms: {
        title: "Coming soon!",
        content: [],
      },
      membershipNotes: {
        title: "",
        content: [],
      },
    },
    pt: {
      title: "Parcerias",
      membershipTerms: {
        title: "Em breve!",
        content: [],
      },
      membershipNotes: {
        title: "",
        content: [],
      },
    },
  },
  company: {
    en: {
      title: "Company Policy",
      membershipTerms: {
        title: "Cancelation and Lateness",
        content: [
          "10 minutes late we do the service normally. In 11 - 15 minutes we do what is possible. 16 minutes you will miss your appointment or pass to do with the barber that is available.",
          "If you have scheduled and confirmed the appointment on the app, you have 24 hours before the appointment to cancel. This way, we have enough time to put another customer on the schedule.",
          "Cancellation with less than 24 hours or no show will be charged 50% of the service.",
        ],
      },
      membershipNotes: {
        title: "Notes",
        content: [
          "These measures are aimed to provide a better customer service. We always cherish the commitment to not delay and be entirely dedicated to the service of each one. It's mpt fair to generate a delay in all the schedule due to late clients. That's why we ser a time limit.",
          "Plausible justifications for delays or no show will be evaluated and taken into account. We count on your common sense and partnership.",
        ],
      },
    },
    pt: {
      title: "Política da Empresa",
      membershipTerms: {
        title: "Politica de Cancelamento",
        content: [
          "10 minutos de atraso fazemos o serviço normalmente. De 11 - 15 minutos fazemos o que dá. 16 minutos perderá seu agendamento (ou fará com quem estiver livre).",
          "Marcou e confirmou o horário no aplicativo, voce tem 24 horas antes do atendimento para cancelar. Dessa forma, temos tempo hábil para colocar outro cliente no horário.",
          "Cancelamento com menos de 24 horas ou o não comparecimento serão cobrados o valor de 50% do atendimento/serviço.",
        ],
      },
      membershipNotes: {
        title: "Observações:",
        content: [
          "Estas medidas visam o melhor atendimento para os clientes. Prezamos sempre pelo compromisso de nao atrasar e estar inteiramente dedicados ao atendimento de cada um. Não ee justo gerar um atraso para toda a agenda, por conta de clientes atrasados. Por isso, estabelecêssemos um tempo limite.",
          "Justificativas plausíveis para atrasos ou não comparecimento serão avaliadas e levadas em consideração, contamos com o bom senso de todos.",
        ],
      },
    },
  },
  membership: {
    en: {
      title: "Membership Policy",
      membershipTerms: {
        title: "Please read carefully:",
        content: [
          "The customer is responsible for scheduling services.",
          "The customer is entirely responsible for making the payment.",
          "The customer must ensure that the chosen payment vehicle is enabled for payment.",
          "Delay or failure to pay will result in the temporary suspension of benefits until the time of regularization.",
          "The deadline for payment regularization is 5 (five) business days. After this period, the membership will be automatically canceled along with all accumulative benefits.",
          "If the customer chooses to cancel the membership, the deadline is 10 (ten) days before the date of the next monthly payment (please pay attention to the date on which the subscription was made).",
          "In case of membership cancellation, the customer will be able to use the services until the end of the monthly cycle corresponding to the last payment made.",
          "After the cancellation, if the customer wishes to return to membership, it will only be possible after 60 days from the date of cancellation.",
          "Our membership does not entitle you to a refund for the month that has already been paid, however we honor access to services and benefits until the end of the monthly cycle already paid by the customer.",
          "If the membership client schedules services and is unable to attend, they must follow our cancellation/rescheduling rules. Failure to do so will result in the charge of a 50% fine equivalent to the value of the scheduled service (check the company policies for greater details).",
          "The membership price is subject to adjustment. Any changes will be communicated with 30 days' notice.",
          "Any disrespectful action towards the barbershop teal may lead to the termination of the membership subscription.",
        ],
      },
      membershipNotes: {
        title: "Notes",
        content: [
          "Remember to schedule your services in advance, use your bonuses and check if we have any temporary extra benefits.",
          "Plausible justifications for delays or non-attendance will be evaluated and taken into consideration, we count on everyone's common sense.",
        ],
      },
    },
    pt: {
      title: "Termos do Membership",
      membershipTerms: {
        title: "Leia com atenção",
        content: [
          "O cliente é responsável pelo agendamento dos serviços.",
          "O cliente é inteiramente responsável pela efetuação do pagamento. ",
          "O cliente deve garantir que o veículo de pagamento escolhido esteja habilitado para o pagamento.",
          "Atraso ou falha no pagamento resultará na suspensão temporária dos benefícios até o momento da regularização.",
          "O prazo para regularização é de 5 (cinco) dias úteis. Após este período o membership será automaticamente cancelado bem como todos os benefícios acumulativos.",
          "Caso o cliente opte pelo cancelamento do membership, a data limite é 10 (dez) dias antes da data do próximo pagamento mensal (por gentiliza, ficar atento a data na qual realizou a assinatura).",
          "Em caso de cancelamento do membership, o cliente poderá utilizar os serviços até o final do ciclo mensal referente ao último pagamento efetuado.",
          "Após o cancelamento, caso o cliente deseje voltar ao membership, só será possível após 60 dias a partir da data do cancelamento.",
          "Nosso membership não da direito ao reembolso do mês que já foi pago, porém honramos o acesso aos serviços e vantagens até o final do ciclo mensal já pago pelo cliente.",
          "Caso o cliente do membership agende serviços e não possa comparecer, o mesmo deve seguir nossa regra de cancelamento/reagendamento. O não cumprimento resultará na cobrança de uma multa de 50% equivalente ao valor do serviço agendado (verifique as políticas da empresa para maiores detalhes).",
          "O valor da mensalidade do membership está sujeito a ajuste. Mudanças serão informadas com 30 dias de antecedência",
          "Tratar o team da barbearia com desrespeito pode resultar no termino do seu membership.",
        ],
      },
      membershipNotes: {
        title: "Observações:",
        content: [
          "Lembre-se de agendar seus serviços com antecedência, utilizar seus bônus e verificar casos tenhamos algum benefício extra temporário.",
          "Justificativas plausíveis para atrasos ou não comparecimento serão avaliadas e levadas em consideração, contamos com o bom senso de todos.",
        ],
      },
    },
  },
  // NOT BEING USED
  payment: {
    en: {
      title: "Payment Policy",
      membershipTerms: {
        title: "Terms and Conditions - EXAMPLE",
        content: [
          "Membership is valid for one year from the date of purchase.",
          "Members are entitled to a 10% discount on all barbershop services.",
          "Members must present their membership card at each visit to claim the discount.",
          "Membership benefits are non-transferable and can only be used by the registered member.",
          "Lost or stolen membership cards should be reported to the barbershop immediately.",
          "The membership fee is non-refundable.",
        ],
      },
      membershipNotes: {
        title: "Notes",
        content: [
          "Please schedule your appointments in advance to ensure availability.",
          "Membership discounts cannot be combined with other promotions or offers.",
          "The barbershop reserves the right to modify or terminate the membership program with prior notice.",
          "Members are encouraged to provide feedback to help us improve our services.",
        ],
      },
    },
    pt: {
      title: "Políticas de Associação",
      membershipTerms: {
        title: "Termos - EXEMPLO",
        content: [
          "A associação é válida por um ano a partir da data da compra.",
          "Os membros têm direito a um desconto de 10% em todos os serviços do barbeiro.",
          "Os membros devem apresentar seu cartão de associação em cada visita para obter o desconto.",
          "Os benefícios da associação não são transferíveis e só podem ser usados pelo membro registrado.",
          "Cartões de associação perdidos ou roubados devem ser comunicados imediatamente ao barbeiro.",
          "A taxa de associação não é reembolsável.",
        ],
      },
      membershipNotes: {
        title: "Notas",
        content: [
          "Por favor, agende seus compromissos com antecedência para garantir disponibilidade.",
          "Os descontos de associação não podem ser combinados com outras promoções ou ofertas.",
          "O barbeiro se reserva o direito de modificar ou encerrar o programa de associação com aviso prévio.",
          "Os membros são incentivados a fornecer feedback para nos ajudar a melhorar nossos serviços.",
        ],
      },
    },
  },
};

export default policies;
