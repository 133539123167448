import React, { useState, useEffect } from "react";
import "./Hero.css";

export default function Hero({ curLanguage }) {
  const [curImgIndex, setCurImgIndex] = useState(0);
  const imagesUrl = [
    "../../../hero-background-1.webp",
    "../../../hero-background-2.webp",
    "../../../hero-background-3.webp",
    "../../../hero-background-4.webp",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurImgIndex((prevIndex) => (prevIndex + 1) % imagesUrl.length);
    }, 15000); // Change image every 15 seconds

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="hero" id="hero">
      <DivTest testUrl={imagesUrl[curImgIndex]} />
      <div className="hero-logo-div">
        <img
          className="hero-logo"
          src="hero-logo-2.webp"
          alt="Company Logo"
        ></img>
        <a href="#cta" className="hero-link">
          {curLanguage === "en" ? "BOOK" : "AGENDAR"}
        </a>
      </div>
    </div>
  );
}

function DivTest({ testUrl }) {
  const styleA = {
    backgroundImage: `linear-gradient(
    to right,
    rgba(0, 0, 0, 0.9),
    rgba(68, 68, 68, 0.5)
  ), url(${testUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "top",
    transition: "background-image 2s ease",
  };

  return <div className={`back-test`} style={styleA}></div>;
}
