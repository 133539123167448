import "./Gallery.css";

export default function Gallery({ curLanguage }) {
  return (
    <div className="component gallery" id="gallery">
      <h2 className="components-header-h2">
        {curLanguage === "en" ? "Gallery" : "Galeria de Fotos"}
      </h2>
      <div className="gallery-box">
        <div className="gallery-div-grid">
          <GalleryImage
            imgAddress="../../gallery-album/gallery-1.webp"
            imgAlt="Barbershop and customers"
          />
          <GalleryImage
            imgAddress="../../gallery-album/gallery-2.webp"
            imgAlt="Staff training"
          />
          <GalleryImage
            imgAddress="../../gallery-album/gallery-3.webp"
            imgAlt="Barber shaving customer"
          />
          <GalleryImage
            imgAddress="../../gallery-album/gallery-4.webp"
            imgAlt="Barber shaving customer"
          />
          <GalleryImage
            imgAddress="../../gallery-album/gallery-5.webp"
            imgAlt="Example of kid's service"
          />
          <GalleryImage
            imgAddress="../../gallery-album/gallery-6.webp"
            imgAlt="Barber shaving customer"
          />
          <GalleryImage
            imgAddress="../../gallery-album/gallery-7.webp"
            imgAlt="Barber shaving customer"
          />
          <GalleryImage
            imgAddress="../../gallery-album/gallery-8.webp"
            imgAlt="Barbershop space"
          />
          <GalleryImage
            imgAddress="../../gallery-album/gallery-9.webp"
            imgAlt="Barber shaving customer"
          />
          <GalleryImage
            imgAddress="../../gallery-album/gallery-10.webp"
            imgAlt="Barber shaving customer"
          />
          <GalleryImage
            imgAddress="../../gallery-album/gallery-11.webp"
            imgAlt="Example of kid's service"
          />
          <GalleryImage
            imgAddress="../../gallery-album/gallery-12.webp"
            imgAlt="Barber shaving customer"
          />
        </div>
      </div>
    </div>
  );
}

function GalleryImage({ imgAddress, imgAlt }) {
  return (
    <>
      <div className="gallery-div-img">
        <img className="gallery-img" src={imgAddress} alt={imgAlt}></img>
      </div>
    </>
  );
}
