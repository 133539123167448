import "./PromptWindow.css";
import React, { useState } from "react";

import { HiOutlineXMark } from "react-icons/hi2";

export default function PromptWindow({
  onPromptOpen,
  onPromptClose,
  onPromptContent,
  curLanguage,
}) {
  let curContentAndLang = "";
  if (onPromptContent) {
    curContentAndLang =
      curLanguage === "en"
        ? onPromptContent.content.en
        : onPromptContent.content.pt;
  }

  if (onPromptContent === null) {
    return (
      <div className={onPromptOpen ? "prompt" : "prompt-hidden"}>
        <div className="prompt-box">
          <div className="prompt-box-header">
            <h2 className="prompt-title">ERROR</h2>
            <button onClick={onPromptClose}>
              <HiOutlineXMark className="nav-mobile-btn-icon" />
            </button>
          </div>
        </div>
      </div>
    );
  }
  // Content must be different of 'null' in order to render the window
  if (onPromptContent === "update") {
    return (
      <div className={onPromptOpen ? "prompt" : "prompt-hidden"}>
        <div className="prompt-box">
          <div className="prompt-box-header">
            <button onClick={onPromptClose} className="prompt-button">
              <HiOutlineXMark className="prompt-button-icon" />
            </button>
          </div>
          {/* <div className="prompt-content">
            <h2 className="prompt-title">{curContentAndLang.title}</h2>
            <h3 className="prompt-notes-h3">
              {curContentAndLang.serviceDescription.title}
            </h3>
            <ul>
              {curContentAndLang.serviceDescription.content.map((item) => (
                <li key={item}>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
            <h3 className="prompt-notes-h3">
              {curContentAndLang.theProcess.title}
            </h3>

            <ol>
              {curContentAndLang.theProcess.content.map((item, index) => (
                <li key={item}>
                  <span>{item}</span>
                </li>
              ))}
            </ol>

            <h3 className="prompt-notes-h3">
              {curContentAndLang.benefits.title}
            </h3>

            <ul>
              {curContentAndLang.benefits.content.map((item) => (
                <li key={item}>
                  <span>{item.title + " "}</span>
                  <span>{item.text}</span>
                </li>
              ))}
            </ul>
            <h3 className="prompt-notes-h3">
              {curContentAndLang.suitableForAllHairTypes.title}
            </h3>
            <ul>
              {curContentAndLang.suitableForAllHairTypes.content.map((item) => (
                <li key={item}>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
            <ul>
              <li>
                <span>{curContentAndLang.suitableForAllHairTypes.title}</span>
              </li>
            </ul>
            <h3 className="prompt-notes-h3">
              {curContentAndLang.maintenance.title}
            </h3>
            <ul>
              {curContentAndLang.maintenance.content.map((item) => (
                <li key={item}>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
            <h3 className="prompt-notes-h3">{curContentAndLang.note.title}</h3>
            <ul>
              {curContentAndLang.note.content.map((item) => (
                <li key={item}>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div> */}
        </div>
      </div>
    );
  }

  //  ------------- Services ------------------
  if (onPromptContent !== null && onPromptContent.frame === "service") {
    return (
      <div className={onPromptOpen ? "prompt" : "prompt-hidden"}>
        <div className="prompt-box">
          <div className="prompt-box-header">
            <h2 className="prompt-title">{curContentAndLang.title}</h2>

            <button onClick={() => onPromptClose()} className="prompt-button">
              <HiOutlineXMark className="prompt-button-icon" />
            </button>
          </div>
          <PromptService
            content={curContentAndLang}
            // planLink={onPromptContent.planLink}
            curLanguage={curLanguage}
          />
        </div>
      </div>
    );
  }
  //  ------------- Policies ------------------
  if (onPromptContent !== null && onPromptContent.frame === "subscription") {
    return (
      <div className={onPromptOpen ? "prompt" : "prompt-hidden"}>
        <div className="prompt-box">
          <div className="prompt-box-header">
            <h2 className="prompt-title">{curContentAndLang.title}</h2>
            <button onClick={() => onPromptClose()} className="prompt-button">
              <HiOutlineXMark className="prompt-button-icon" />
            </button>
          </div>
          <PromptSubscription
            content={curContentAndLang}
            planLink={onPromptContent.planLink}
            curLanguage={curLanguage}
          />
        </div>
      </div>
    );
  }
  //  ------------- Policies ------------------
  if (onPromptContent !== null && onPromptContent.frame === "policy") {
    return (
      <div className={onPromptOpen ? "prompt" : "prompt-hidden"}>
        <div className="prompt-box">
          <div className="prompt-box-header">
            <h2 className="prompt-title">{curContentAndLang.title}</h2>
            <button onClick={() => onPromptClose()} className="prompt-button">
              <HiOutlineXMark className="prompt-button-icon" />
            </button>
          </div>
          <PromptPolicy
            content={curContentAndLang}
            curLanguage={curLanguage}
            // planLink={onPromptContent.planLink}
          />
        </div>
      </div>
    );
  }
}

function PromptService({ curLanguage, content, planLink }) {
  return (
    <>
      <div className="prompt-content">
        {/* <h2 className="prompt-title">{content.title}</h2> */}
        <h3 className="prompt-notes-h3">{content.serviceDescription.title}</h3>
        <ul>
          {content.serviceDescription.content.map((item) => (
            <li key={item}>
              <span>{item}</span>
            </li>
          ))}
        </ul>
        <h3 className="prompt-notes-h3">{content.theProcess.title}</h3>

        <ol>
          {content.theProcess.content.map((item, index) => (
            <li key={item}>
              <span>{item}</span>
            </li>
          ))}
        </ol>

        <h3 className="prompt-notes-h3">{content.benefits.title}</h3>

        <ul>
          {content.benefits.content.map((item) => (
            <li key={item}>
              <span>{item.title + " "}</span>
              <span>{item.text}</span>
            </li>
          ))}
        </ul>
        <h3 className="prompt-notes-h3">
          {content.suitableForAllHairTypes.title}
        </h3>
        <ul>
          {content.suitableForAllHairTypes.content.map((item) => (
            <li key={item}>
              <span>{item}</span>
            </li>
          ))}
        </ul>
        <ul>
          {/* <li>
            <span>{content.suitableForAllHairTypes.title}</span>
          </li> */}
        </ul>
        {/* <h3 className="prompt-notes-h3">{content.maintenance.title}</h3> */}
        {/* <ul>
          {content.maintenance.content.map((item) => (
            <li key={item}>
              <span>{item}</span>
            </li>
          ))}
        </ul> */}
        <h3 className="prompt-notes-h3">{content.note.title}</h3>
        <ul>
          {content.note.content.map((item) => (
            <li key={item}>
              <span>{item}</span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

function PromptSubscription({ curLanguage, content, planLink }) {
  const [accept, setAccept] = useState(false);

  function handleAcceptClick(e) {
    e.preventDefault();
    if (accept) {
      // Redirect to the specified link when the button is clicked and the checkbox is checked
      window.location.href = planLink;
    }
  }

  return (
    <>
      <div className="prompt-content">
        {/* <h2 className="prompt-title">{content.title}</h2> */}
        <h3 className="prompt-notes-h3">{content.membershipTerms.title}</h3>
        <ol>
          {content.membershipTerms.content.map((item) => (
            <li key={item}>
              <span>{item}</span>
            </li>
          ))}
        </ol>
        <h3 className="prompt-notes-h3">{content.membershipNotes.title}</h3>

        <ul>
          {content.membershipNotes.content.map((item) => (
            <li key={item}>
              <span>{item}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="prompt-form-div">
        <form className="prompt-form">
          <label className="prompt-form-label">
            <input
              className="prompt-form-input"
              type="checkbox"
              checked={accept}
              onChange={() => setAccept(!accept)}
            />
            <span>
              {curLanguage === "en"
                ? "I understand and accept the terms and conditions."
                : "Eu entendo e aceito os termos e condições."}
            </span>
          </label>
          <label className="prompt-form-label">
            <button
              disabled={!accept}
              onClick={(e) => handleAcceptClick(e)}
              className="prompt-form-button"
            >
              {curLanguage === "en" ? "Next" : "Próximo"}
            </button>
          </label>
        </form>
      </div>
    </>
  );
}
function PromptPolicy({ curLanguage, content, planLink }) {
  return (
    <>
      <div className="prompt-content">
        {/* <h2 className="prompt-title">{content.title}</h2> */}
        <h3 className="prompt-notes-h3">{content.membershipTerms.title}</h3>
        <ol>
          {content.membershipTerms.content.map((item) => (
            <li key={item}>
              <span>{item}</span>
            </li>
          ))}
        </ol>
        <h3 className="prompt-notes-h3">{content.membershipNotes.title}</h3>

        <ul>
          {content.membershipNotes.content.map((item) => (
            <li key={item}>
              <span>{item}</span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
