//////////////////////////////////////////////////////////////////////////////////////////////////////////// FINAL
const servicesData = {
  //
  //
  //
  //
  //////////////////////////////////////////////// BARBERSHOP CARD
  haircut: {
    en: {
      title: "Haircut*",
      serviceDescription: {
        title: "Service Description",
        content: [
          "Experience the art of a precision haircut at our barbershop. Our skilled barbers and hairstylists focus on providing you with a sharp, modern look that matches your individual style and preferences.",
        ],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "Suitable for All Hair Types",
        content: [
          "Whether you have straight, wavy, or curly hair, our barbers have the expertise to provide you with a top-quality haircut.",
        ],
      },
      note: {
        title: "Note:",
        content: [
          "If you have any specific requests or preferences, please let our barbers know during the consultation.",
        ],
      },
    },
    pt: {
      title: "Corte*",
      serviceDescription: {
        title: "Descrição do Serviço",
        content: [
          "Corte de cabelo seguindo estilo e preferências individuais do cliente.",
        ],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "Adequado para Todos os Tipos de Cabelo",
        content: [
          "Independentemente de você ter cabelos lisos, ondulados, ou cacheados, nossos barbeiros têm a expertise para lhe fornecer um corte de cabelo de alta qualidade.",
        ],
      },
      note: {
        title: "Observações:",
        content: [
          "Se você tiver algum pedido específico ou preferência, por favor, informe nossos barbeiros durante a consulta.",
        ],
      },
    },
  },

  // ------------------------------------------------------------------------------- KIDS HAIRCUT
  kidsHaircut: {
    en: {
      title: "Kid's Haircut",
      serviceDescription: {
        title: "Service Description",
        content: [
          "A kid's haircut service (under age 6) with parents a present during the process.",
        ],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },

      note: {
        title: "Note:",
        content: [
          "If you have any specific requests, please let our stylists know during the consultation.",
        ],
      },
    },
    pt: {
      title: "Corte Kids",
      serviceDescription: {
        title: "Descrição do Serviço",
        content: [
          "Corte de cabelo para crianças (menos de 6 anos) acompanhadas pelos pais.",
        ],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },
      note: {
        title: "Observações:",
        content: [
          "Se você tem algum pedido ou preferência, por favor, informe a nossos estilistas durante a consulta.",
        ],
      },
    },
  },

  // ------------------------------------------------------------------------------- MACHINE ONLY HAIRCUT

  haircutWithMachine: {
    en: {
      title: "Haircut with Machine*",
      serviceDescription: {
        title: "Service Description",
        content: ["A haircut using only trimmers."],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },

      note: {
        title: "Note:",
        content: [
          "If you have specific requests or preferences, please let our barbers know during the consultation.",
        ],
      },
    },
    pt: {
      title: "Corte Só Máquina*",
      serviceDescription: {
        title: "Descrição do Serviço",
        content: [
          "Processo de corte de cabelo simplificado apenas com máquina.",
        ],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },

      note: {
        title: "Observações:",
        content: [
          "Se você tem pedidos ou preferências, por favor, informe nossos barbeiros durante a consulta.",
        ],
      },
    },
  },

  // ------------------------------------------------------------------------------- HEAD SHAVE

  headShave: {
    en: {
      title: "Head Shave*",
      serviceDescription: {
        title: "Service Description",
        content: ["Head shaving service following customer's preferences."],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },

      note: {
        title: "Note:",
        content: [
          "If you have specific requests or preferences, please let our barbers know during the consultation.",
        ],
      },
    },
    pt: {
      title: "Raspar Cabeça*",
      serviceDescription: {
        title: "Descrição do Serviço",
        content: ["Raspar a cabeça seguindo preferências do cliente."],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },

      note: {
        title: "Observações:",
        content: [
          "Se você tem pedidos ou preferências específicas, por favor, informe nossos barbeiros durante a consulta.",
        ],
      },
    },
  },

  // ------------------------------------------------------------------------------- HOT TOWEL SHAVE

  hotTowelShave: {
    en: {
      title: "Hot Towel Shave*",
      serviceDescription: {
        title: "Service Description",
        content: [
          "A shaving service with hot towel bringing style and maintaining yor beard.",
        ],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },

      note: {
        title: "Note:",
        content: [
          "If you have specific requests or preferences, please let our barbers know during the consultation.",
        ],
      },
    },
    pt: {
      title: "Barba (Hot Towel Shave)*",
      serviceDescription: {
        title: "Descrição do Serviço",
        content: [
          "Barbear acompanhado de Hot Towel (toalha aquecida) promovendo estilo e manutenção da barba.",
        ],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },

      note: {
        title: "Observações:",
        content: [
          "Se você tem pedidos ou preferências específicas, por favor, informe nossos barbeiros durante a consulta.",
        ],
      },
    },
  },

  // ------------------------------------------------------------------------------- LINE UP

  lineUp: {
    en: {
      title: "Line Up*",
      serviceDescription: {
        title: "Service Description",
        content: [
          "The Line up consists in design the hair's perimeters following customers preferences.",
        ],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },

      note: {
        title: "Note:",
        content: [
          "If you have specific requests or preferences, please let our barbers know during the consultation.",
        ],
      },
    },
    pt: {
      title: "Pezinho*",
      serviceDescription: {
        title: "Descrição do Serviço",
        content: [
          "O Pezinho consiste em fazer um acabamento ao redor do cabelo.",
        ],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },

      note: {
        title: "Observações:",
        content: [
          "Se você tem pedidos ou preferências específicas, por favor, informe nossos barbeiros durante a consulta.",
        ],
      },
    },
  },

  //
  //
  //
  //
  //////////////////////////////////////////////// OTHER SERVICES CARD

  // ------------------------------------------------------------------------------- EYEBROW SHAPING

  eyebrowsShaping: {
    en: {
      title: "Eyebrows",
      serviceDescription: {
        title: "Service Description",
        content: [
          "Enhance your appearance by removing excess eyebrow hair, promoting greater facial harmony.",
        ],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },
      note: {
        title: "",
        content: [],
      },
    },
    pt: {
      title: "Sobrancelhas",
      serviceDescription: {
        title: "Descrição do Serviço",
        content: [
          "Melhore o seu visual removendo pelos excessivos da sobrancelha promovendo mais harmonia do formato do rosto.",
        ],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },
      note: {
        title: "",
        content: [],
      },
    },
  },

  // ------------------------------------------------------------------------------- EAR WAXING

  earWaxing: {
    en: {
      title: "Ear Waxing",
      serviceDescription: {
        title: "Service Description",
        content: ["Removal of unwanted hair in the ear area using wax."],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },
      note: {
        title: "",
        content: [],
      },
    },
    pt: {
      title: "Depilação Orelhas",
      serviceDescription: {
        title: "Descrição do Serviço",
        content: [
          "Remoção de pelos indesejados na região das orelhas utilizando cera.",
        ],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },

      note: {
        title: "",
        content: [],
      },
    },
  },

  // ------------------------------------------------------------------------------- NOSE WAXING

  noseWaxing: {
    en: {
      title: "Nose Waxing",
      serviceDescription: {
        title: "Service Description",
        content: ["Removal of unwanted hair in the nose area using wax."],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },

      note: {
        title: "",
        content: [],
      },
    },
    pt: {
      title: "Depilação  Nariz",
      serviceDescription: {
        title: "Descrição do Serviço",
        content: [
          "Remoção de pelos indesejados na região do nariz utilizando cera.",
        ],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },

      note: {
        title: "",
        content: [],
      },
    },
  },
  // ------------------------------------------------------------------------------- BLACK FACE MASK

  blackFaceMask: {
    en: {
      title: "Black Mask",
      serviceDescription: {
        title: "Service Description",
        content: ["The Black Mask provides a deep cleaning of the face."],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },

      note: {
        title: "Note:",
        content: [
          "If you have specific requests or preferences, please let our specialist know during the consultation.",
        ],
      },
    },
    pt: {
      title: "Black Mask",
      serviceDescription: {
        title: "Descrição do Serviço",
        content: ["O Black Mask vai promover a limpeza profunda do rosto."],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },

      note: {
        title: "Nota:",
        content: [
          "Se você tem preocupações específicas com a pele ou preferências, por favor comunique-as aos nossos especialistas durante a consulta.",
        ],
      },
    },
  },

  // ------------------------------------------------------------------------------- SCRUB HAIR

  scrubHair: {
    en: {
      title: "Scrub",
      serviceDescription: {
        title: "Service Description",
        content: [
          "Designed for those dealing with dandruff and scalp skin issues, give our Scrub a try.",
        ],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },

      note: {
        title: "",
        content: [],
      },
    },
    pt: {
      title: "Scrub",
      serviceDescription: {
        title: "Descrição do Serviço",
        content: [
          "Criado pensando naqueles que sofrem com caspa e problemas na pele do couro cabeludo e cabelo, experimente nosso Scrub.",
        ],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },

      note: {
        title: "",
        content: [],
      },
    },
  },
  //
  //
  //
  //
  //////////////////////////////////////////////// CHEMICALS CARD
  // ------------------------------------------------------------------------------- BRAZILIAN BLOW OUT
  brazilianBlowout: {
    en: {
      title: "Brazilian Blowout",
      serviceDescription: {
        title: "Service Description",
        content: [
          "Are you tired of unmanageable hair? Experience the Brazilian Blowout, a premium hair treatment that will bring your hair to best.",
        ],
      },
      theProcess: {
        title: "The Process:",
        content: [
          "Our stylists will start by thoroughly cleansing your hair to remove any impurities. Next, we'll apply the Brazilian Blowout solution to your hair. This solution is designed to create a protective layer around your hair shaft, sealing in moisture and eliminating frizz.",
          "Using advanced heat tools, we'll then meticulously smooth and straighten your hair. The result? Silky, shiny, and effortlessly manageable hair that lasts for weeks.",
        ],
      },
      benefits: {
        title: "Benefits",
        content: [
          {
            title: "Enhanced Manageability:",
            text: "No more frizz. Effortless manageable hair. A Brazilian Blowout provides a sleek and smooth finish that lasts.",
          },
          {
            title: "Low Maintenance:",
            text: "Maintenance is hassle-free. Simply use the recommended sulfate-free hair care products to extend the life of your treatment.",
          },
        ],
      },
      suitableForAllHairTypes: {
        title: "Suitable for All Hair Types",
        content: [
          "It's suitable for all hair types and is especially beneficial for those with frizzy, damaged, or unmanageable hair.",
        ],
      },
      note: {
        title: "Note:",
        content: [
          "If you have specific requests or preferences, please let our specialist know during the consultation.",
        ],
      },
    },

    pt: {
      title: "Selagem",
      serviceDescription: {
        title: "Descrição do Serviço",
        content: [
          "Está cansado de cabelos difíceis de controlar? Experimente a selagem, um tratamento capilar premium que deixará seu cabelo sem frizz e fácil de manter.",
        ],
      },
      theProcess: {
        title: "O Processo:",
        content: [
          "Nossos estilistas começarão limpando completamente o seu cabelo para remover impurezas. Em seguida, aplicaremos a solução. Essa solução é projetada para criar uma camada protetora em torno do eixo do seu cabelo, selando a umidade e eliminando o frizz.",
          "Usando ferramentas de calor avançadas, alisaremos e alinharemos meticulosamente o seu cabelo. O resultado? Cabelos sedosos, brilhantes e facilmente controláveis que duram semanas.",
        ],
      },
      benefits: {
        title: "Benefícios",
        content: [
          {
            title: "Facilidade de Gerenciamento:",
            text: "Não mais frizz. Cabelo facilmente controlável. O Brazilian Blowout proporciona um acabamento liso e suave que dura.",
          },
          {
            title: "Baixa Manutenção:",
            text: "A manutenção é sem complicações.",
          },
        ],
      },
      suitableForAllHairTypes: {
        title: "Adequado para Todos os Tipos de Cabelo",
        content: [
          "É adequado para todos os tipos de cabelo e é especialmente benéfico para aqueles com cabelos com frizz, danificados ou difíceis de controlar.",
        ],
      },
      note: {
        title: "Observação:",
        content: [
          "Se você tiver solicitações ou preferências específicas, informe nosso especialista durante a consulta.",
        ],
      },
    },
  },

  // ------------------------------------------------------------------------------- HIGHLIGHTS

  highlights: {
    en: {
      title: "Highlights",
      serviceDescription: {
        title: "Service Description",
        content: [
          "Illuminate your hair and elevate your look with Highlights. Whether you desire natural tones or bold, creative streaks, our skilled colorists will bring your vision to life.",
        ],
      },
      theProcess: {
        title: "The Process",
        content: [
          "Our team will consult with you to understand your preferences. We'll carefully select the most suitable shade or shades for your hair type and skin tone.",
          "Using precision techniques, we'll apply the highlights strategically, ensuring a natural and harmonious blend with your base hair color.",
        ],
      },
      benefits: {
        title: "Benefits",
        content: [
          {
            title: "Enhanced Dimension:",
            text: "Highlights create depth and movement in your hair, making it appear more voluminous and dynamic.",
          },
        ],
      },
      suitableForAllHairTypes: {
        title: "Suitable for All Hair Types",
        content: [
          "Highlights are a versatile option for people of all hair types and lengths.",
        ],
      },
      note: {
        title: "Note:",
        content: [
          "If you have specific requests or preferences, please let our specialist know during the consultation.",
        ],
      },
    },
    pt: {
      title: "Luzes",
      serviceDescription: {
        title: "Descrição do Serviço",
        content: [
          "Ilumine seu cabelo e eleve seu visual com Luzes. Seja desejando tons naturais ou mechas ousadas e criativas, nossos habilidosos coloristas darão vida à sua visão.",
        ],
      },
      theProcess: {
        title: "O Processo",
        content: [
          "Nossa equipe irá consultar você para entender suas preferências. Selecionaremos cuidadosamente a tonalidade ou tonalidades mais adequadas para o seu tipo de cabelo e tom de pele.",
          "Usando técnicas de precisão, aplicaremos as mechas estrategicamente, garantindo uma mistura natural e harmoniosa com a cor do seu cabelo base.",
        ],
      },
      benefits: {
        title: "Benefícios",
        content: [
          {
            title: "Dimensão Aprimorada:",
            text: "As mechas criam profundidade e movimento no seu cabelo, tornando-o mais volumoso e dinâmico.",
          },
        ],
      },
      suitableForAllHairTypes: {
        title: "Adequado para Todos os Tipos de Cabelo",
        content: [
          "Mechas são uma opção versátil para pessoas de todos os tipos e comprimentos de cabelo.",
        ],
      },
      note: {
        title: "Observação",
        content: [
          "Se você tiver solicitações ou preferências específicas, informe nosso especialista durante a consulta.",
        ],
      },
    },
  },

  // ------------------------------------------------------------------------------- ALL BLOND

  allBlond: {
    en: {
      title: "All Blonde",
      serviceDescription: {
        title: "Service Description",
        content: [
          "It consists in globally lightening your hair, removing the natural shade to create a consistent and completely blonde look.",
        ],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "Benefits",
        content: [
          {
            title: "",
            text: "Flawless and personalized blonde shade that suits you perfectly.",
          },
        ],
      },
      suitableForAllHairTypes: {
        title: "Suitable for All Hair Types",
        content: [
          "Whether you have straight, wavy, or curly hair, the All Blonde treatment is for you.",
        ],
      },
      note: {
        title: "Note:",
        content: [
          "If you have specific requests or preferences, please let our specialist know during the consultation.",
        ],
      },
    },
    pt: {
      title: "Platinado",
      serviceDescription: {
        title: "Descrição do Serviço",
        content: [
          "Consiste em descolorir o cabelo de forma global removendo a tonalidade natural criando um visual uniforme e completamente loiro.",
        ],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "Benefícios",
        content: [
          {
            title: "",
            text: "Um tom loiro/branco impecável e personalizado que combina perfeitamente com você.",
          },
        ],
      },
      suitableForAllHairTypes: {
        title: "Adequado para Todos os Tipos de Cabelo",
        content: [
          "Se você tem cabelo liso, ondulado ou cacheado, o tratamento Platinado é para você.",
        ],
      },
      note: {
        title: "Observação",
        content: [
          "Se você tiver solicitações ou preferências específicas, informe nosso especialista durante a consulta.",
        ],
      },
    },
  },

  // ------------------------------------------------------------------------------- HAIR COLORING
  hairColoring: {
    en: {
      title: "Hair & Beard Coloring",
      serviceDescription: {
        title: "Service Description",
        content: [
          "Hair & Beard Coloring service is designed to help gentlemen of all ages achieve the look they desire while enhancing their natural style and confidence. Whether you're seeking to cover up grays or experiment with a bold new color, we can help you.",
        ],
      },
      theProcess: {
        title: "The Process:",
        content: [
          "Our stylists will begin by assessing your current hair/beard color and texture. Then, we'll apply our specially selected hair coloring solution, ensuring even coverage.",
        ],
      },
      benefits: {
        title: "Benefits",
        content: [
          {
            title: "Customized Color:",
            text: "Say goodbye to dull or faded hair/beard color.",
          },
        ],
      },
      suitableForAllHairTypes: {
        title: "Suitable for All Hair/Beard Types",
        content: [
          "It's suitable for all hair/beard types and is perfect for those seeking to upgrade the hair/beard color.",
        ],
      },

      note: {
        title: "Note:",
        content: [
          "If you have specific requests or preferences, please let our specialist know during the consultation.",
        ],
      },
    },
    pt: {
      title: "Coloração Barba e Cabelo",
      serviceDescription: {
        title: "Descrição do Serviço",
        content: [
          "O serviço de Coloração foi projetado para ajudar pessoas de todas as idades a alcançar o visual desejado, enquanto realçam seu estilo natural e confiança. Esteja você procurando cobrir os cabelos ou barba grisalhos ou experimentar uma nova cor nosso time pode de ajudar.",
        ],
      },
      theProcess: {
        title: "O Processo:",
        content: [
          "Começaremos avaliando a cor e a textura atual do seu cabelo/barba. Em seguida, aplicaremos nossa solução de coloração garantindo uma cobertura uniforme.",
        ],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "Adequado para Todos os Tipos de Cabelo e Barba",
        content: [
          "É adequado para todos os tipos de cabelo/barba e é perfeito para aqueles que buscam um upgrade do visual.",
        ],
      },
      note: {
        title: "Observação",
        content: [
          "Se você tiver solicitações ou preferências específicas, informe nosso especialista durante a consulta.",
        ],
      },
    },
  },

  // ------------------------------------------------------------------------------- HAIR HYDRATION
  hairHydration: {
    en: {
      title: "Hair Hydration",
      serviceDescription: {
        title: "Service Description",
        content: [
          "You know that feeling when you're in the shower, and your hair is falling out more than usual? This could be due to a lack of proper care. Our hair requires attention, and hydration is crucial to maintain its health. This is especially important for those of you who work in construction or are exposed to the sun, as it can exacerbate hair problems and potentially lead to issues like baldness, dandruff, and seborrhea in the future. I'm sure you'd want to avoid that, right?",
        ],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "Benefits",
        content: [
          {
            title: "Benefits of Hydration:",
            text: "Hydration promotes hair health, leaving your hair soft, shiny, and preventing hair loss. It also helps cleanse impurities, guards against dryness, and controls frizz.",
          },
        ],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },
      note: {
        title: "Note:",
        content: [
          "If you have specific requests or preferences, please let our specialist know during the consultation.",
        ],
      },
    },
    pt: {
      title: "Hidratação",
      serviceDescription: {
        title: "Descrição do Serviço",
        content: [
          "Sabe quando você está tomando banho e seu cabelo está caindo cada vez mais? Isso pode ser por falta dos cuidados certos. Nosso cabelo precisa dos cuidados e a hidratação é essencial para manter a saúde do seu cabelo. Principalmente você que trabalha na construção ou exposto ao sol isso prejudica mais e mais podendo no futuro causar calvície, caspa, seborreia… e você não quer isso né. ",
        ],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "Benefícios",
        content: [
          {
            title: "Benefícios da hidratação:",
            text: "Auxilia na saúde capilar, deixando os cabelos macios, brilhosos, previne contra queda, limpa impurezas, previne o ressecamento e controle de frizz.",
          },
        ],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },
      note: {
        title: "Observação:",
        content: [
          "Se você tiver pedidos ou preferências específicas, por favor, informe nosso especialista durante a consulta.",
        ],
      },
    },
  },
  // ------------------------------------------------------------------------------- BEARD COLORING

  // beardColoring: {
  //   en: {
  //     title: "Beard Coloring",
  //     serviceDescription: {
  //       title: "Service Description",
  //       content: [
  //         "Revitalize your beard with our Beard Coloring service, a premium transformation that will leave you with a vibrant and impeccably colored beard.",
  //       ],
  //     },
  //     theProcess: {
  //       title: "The Process:",
  //       content: [
  //         "Our stylists will begin by assessing your current beard color. Then, we'll apply our specially selected coloring solution, ensuring even coverage.",
  //         "Using advanced coloring techniques, we'll achieve a flawless, customized color that complements your skin tone and style.",
  //       ],
  //     },
  //     benefits: {
  //       title: "Benefits",
  //       content: [
  //         {
  //           title: "Customized Color:",
  //           text: "Say goodbye to dull or faded beard.",
  //         },
  //       ],
  //     },
  //     suitableForAllHairTypes: {
  //       title: "",
  //       content: [],
  //     },

  //     note: {
  //       title: "Note:",
  //       content: [
  //         "If you have specific requests or preferences, please let our specialist know during the consultation.",
  //       ],
  //     },
  //   },
  //   pt: {
  //     title: "Coloração Barba e Cabelo",
  //     serviceDescription: {
  //       title: "Descrição do Serviço",
  //       content: [
  //         "Revitalize sua barba com nosso serviço de Coloração Barba, uma transformação premium que deixará você com uma barba vibrante.",
  //       ],
  //     },
  //     theProcess: {
  //       title: "O Processo:",
  //       content: [
  //         "Nossos estilistas começarão avaliando a cor atual de sua barba. Em seguida, aplicaremos nossa solução de coloração especialmente selecionada, garantindo uma cobertura uniforme.",
  //         "Usando técnicas avançadas de coloração, alcançaremos uma cor impecável e personalizada que combina com o tom da sua pele e estilo.",
  //       ],
  //     },
  //     benefits: {
  //       title: "Benefícios",
  //       content: [
  //         {
  //           title: "Cor Personalizada:",
  //           text: "Diga adeus à barba opaca ou desbotada.",
  //         },
  //       ],
  //     },
  //     suitableForAllHairTypes: {
  //       title: "",
  //       content: [],
  //     },
  //     note: {
  //       title: "Observação:",
  //       content: [
  //         "Se você tiver pedidos ou preferências específicas, por favor, informe nosso especialista durante a consulta.",
  //       ],
  //     },
  //   },
  // },

  // ------------------------------------------------------------------------------- BEARD HYDRATION

  beardHydration: {
    en: {
      title: "Beard Hydration",
      serviceDescription: {
        title: "Service Description",
        content: [
          "Revitalize your beard with our Beard Hydration service, a premium treatment that will leave your beard smoother, with less frizz and hydrated.",
        ],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },
      note: {
        title: "Note:",
        content: [
          "If you have specific requests or preferences, please let our specialist know during the consultation.",
        ],
      },
    },
    pt: {
      title: "Hidratação Barba",
      serviceDescription: {
        title: "Descrição do Serviço",
        content: [
          "Revitalize sua barba com nosso serviço de Hidratação de Barba, um tratamento premium que deixará sua barba mais macia, com menos frizz e hidratada.",
        ],
      },
      theProcess: {
        title: "",
        content: [],
      },
      benefits: {
        title: "",
        content: [],
      },
      suitableForAllHairTypes: {
        title: "",
        content: [],
      },
      note: {
        title: "Observação:",
        content: [
          "Se você tiver pedidos ou preferências específicas, por favor, informe nosso especialista durante a consulta.",
        ],
      },
    },
  },
};

export default servicesData;
